import React, { useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Content from '../atoms/Content'
import styled from 'styled-components'
import google from '../images/clients/google.png'
import pbs from '../images/clients/pbs.png'
import youtube from '../images/clients/youtube.png'
import zagat from '../images/clients/zagat.png'
import hearst from '../images/clients/hearst.png'
import atlanticRecords from '../images/clients/atlantic_records.png'
import historyChannel from '../images/clients/history_channel.png'
import comedyCentral from '../images/clients/comedy_central.png'
import economist from '../images/clients/economist.png'
import usaNetwork from '../images/clients/usa_network.png'
import westin from '../images/clients/westin.png'
import nbc from '../images/clients/nbc.png'
import VanillaTilt from 'vanilla-tilt'

const Clients = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-auto-rows: 160px;
  grid-gap: 1rem;
  margin: 0;
  padding: 0;
  justify-items: center;

  li {
    list-style: none;
    background-color: #fff;
    width: 100%;
    max-width: 256px;
    height: 152px;
    display: flex;
    justify-content: center;
    padding: 14px;
    border-radius: 6px;
    box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
  }

  li img {
    align-self: center;
  }
`

const SubHeader = styled.h2`
  font-weight: 200;
  margin-bottom: 0px;
`

const YouBlock = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right bottom, #8a2387, #e94057, #f27121);
  transform: perspective(500px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);

  a {
    color: #fff;
    font-size: 3rem;
    align-self: center;
    background-color: inherit;
    border: none;

    :hover {
      background-color: inherit;
      border: none;
    }
  }
`

export default function Work({ data, location }) {
  const siteTitle = data.site.siteMetadata.title

  const youRef = useRef(null)
  // useEffect(() => {
  //   VanillaTilt.init(youRef.current, {
  //     scale: 1.25,
  //     reverse: true,
  //   })
  // }, [])

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Work" keywords={[`work`, `python`, `javascript`, `react`]} />
      <Content>
        <h1>Let's Work Together</h1>
        <p>I love helping clients with the following:</p>
        <ul>
          <li>Web development</li>
          <li>Mobile development</li>
          <li>API design and development</li>
          <li>Data and ETL pipelines</li>
        </ul>
        <p>
          I mostly work with <strong>JavaScript</strong> (React, React Native,
          GatsbyJS) and <strong>Python</strong> (Django, Flask, Falcon), but
          also have extensive experience with <strong>PHP</strong> and{' '}
          <strong>Elixir</strong>.
        </p>
        <p css={{ 'margin-top': '40px' }}>
          <strong>
            Have any questions?{' '}
            <a href="mailto:steve@stevemerc.com">Email me</a> and let's chat.
          </strong>
        </p>

        <p css={{ 'margin-top': '40px' }}>
          I've been very fortunate to work with some amazing people and
          companies.
        </p>

        <Clients>
          <li>
            <img src={google} alt="Google" />
          </li>
          <li>
            <img src={pbs} alt="PBS" />
          </li>
          <li>
            <img src={youtube} alt="YouTube" />
          </li>
          <li>
            <img src={zagat} alt="Zagat" />
          </li>
          <li>
            <img src={nbc} alt="NBC" />
          </li>
          <li>
            <img src={hearst} alt="Hearst" />
          </li>
          <li>
            <img src={historyChannel} alt="History Channel" />
          </li>
          <li>
            <img src={economist} alt="The Economist" />
          </li>
          <li>
            <img src={comedyCentral} alt="Comedy Central" />
          </li>
          <li>
            <img src={atlanticRecords} alt="Atlantic Records" />
          </li>
          <li>
            <img src={usaNetwork} alt="USA Network" />
          </li>
          <li>
            <img src={westin} alt="Google" />
          </li>
          {/* <li>STARZ PLACEHOLDER</li>
          <li>COLUMBIA UNIVERSITY PLACEHOLDER</li> */}
          {/* <li>
            <YouBlock ref={youRef}>
              <a href="mailto:steve@stevemerc.com">YOU?</a>
            </YouBlock>
          </li> */}
        </Clients>
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
